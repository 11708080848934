<template>
  <module-login>
    <div class="body">
      <au-input
        v-model:value="username.value"
        :requisite="true"
        placeholder="请输入手机号"
        :matchs="username.matchs"
        icon="el-icon-mobile"
      ></au-input>
      <au-input
        v-model:value="password.value"
        type="password"
        :requisite="true"
        placeholder="请输入密码"
        :matchs="password.matchs"
        icon="el-icon-lock"
      ></au-input>
      <el-row class="info" align="middle" justify="space-between">
        <el-checkbox v-model="isSavePassword">记住密码</el-checkbox>
        <el-link href="javascript:;" type="primary">忘记密码？</el-link>
      </el-row>
      <div class="submit">
        <el-button class="submitBtn" type="primary" size="medium">登录</el-button>
      </div>
      <p>
        登录即表示同意土豆查
        <el-link class="a" type="primary">服务协议</el-link>
        与
        <el-link class="a" type="primary">隐私条款</el-link>
      </p>
    </div>
  </module-login>
</template>

<script>
import auInput from "./components/login/auInput.vue";
import ModuleLogin from './components/login/moduleLogin.vue';
export default {
  components: {  ModuleLogin,auInput },
  data() {
    return {
      isSavePassword:false,
      username: {
        value: "",
        matchs: [
          {
            match: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
      },
      password: {
        value: "",
        matchs: [
          {
            match: /^[a-zA-Z0-9\._=!@#$%^&*\(\)]{6,16}$/,
            message: "密码由6-16位的字母、数字组合而成",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>

.body {
  padding: 30px 15px;
  display: flex;
  flex-flow: column nowrap;
  &>*{
    margin-bottom: 8px;
  }
  p{
    color:var(--au-color-font-sub);
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    .a{
      font-size: 12px;
      position: relative;
      top: -2px;
    }
  }
  .info{
    position: relative;
    top: -10px;
  }
  .submit{
    padding: 15px 0 0;
    .submitBtn{
      width: 100%;
      padding: 14px;
      font-size: 16px;
      letter-spacing: 4px;
      font-weight: bold;
    }
  }
}

</style>